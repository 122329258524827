import React from 'react'

import '../styles/testimonials.scss'

import pic from '../../content/assets/morgan.jpg'

export default () => {
  return (
    <div class='section testimonial card'>
      <p class='quote'><span>“</span> The insights we get from Nucleus are super helpful to direct our roadmap and build things our clients actually care about. <span>”</span></p>
      <div class='author'>
        <img src={pic} />
        <div>
          <h3>Morgan Pelissier</h3>
          {/* fix error: Using target="_blank" without rel="noreferrer" is a security risk */}
          <p>Founder of <a href='https://sparkmate.co' rel='noreferrer' target='_blank'>Sparkmate</a></p>
        </div>
      </div>
    </div>
  )
}
