import React from 'react'
// import { Link, graphql } from 'gatsby' //'Link' is defined but never used. //'graphql' is defined but never used.

import Layout from '../components/layout'
import Seo from '../components/seo' // Imported JSX component SEO must be in PascalCase
import BigHeader from '../components/bigHeader'
import ValueProp from '../components/valueProp'
import Showcase from '../components/showcase'
import Features from '../components/features'
import Testimonials from '../components/testimonials'
import Faq from '../components/faq' // Imported JSX component FAQ must be in PascalCase
import Cta from '../components/cta' // Imported JSX component CTA must be in PascalCase
import Frameworks from '../components/frameworks'

const titleSeo = 'analytics and bug tracking for desktop apps'
const title = '<b>Create stellar apps that users love.</b>'
const description = 'Real-time analytics with error reporting for desktop apps. <br/>As simple as including a module or pinging the API.'

export default ({ data, location }) => {
  return (
    <Layout isHome>
      <Seo title={titleSeo} description={description} />
      <BigHeader what='desktop' title={title} description={description} />
      <Frameworks />
      <Showcase />
      <ValueProp />
      <Features />
      <Cta />
      <Faq />
      <Testimonials />
    </Layout>
  )
}
