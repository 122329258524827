import React from 'react'

import '../styles/frameworks.scss'

import electron from '../../content/assets/icons/electron.svg'
import nodejs from '../../content/assets/icons/javascript.svg'
import swift from '../../content/assets/icons/swift.svg'
import python from '../../content/assets/icons/python.svg'
import reactNative from '../../content/assets/icons/react.svg'

export default () => {
  return (
    <div className='frameworks'>
      <div>
        <h2>For the apps of tomorrow.</h2>
        <div>
          <a href='/electron'><img src={electron} alt='Electron' /></a>
          <a href='/nodejs'><img src={nodejs} alt='Node.js' /></a>
          <a href='/python'><img src={python} alt='Python' /></a>
          <a href='/swift'><img src={swift} alt='Swift' /></a>
        </div>
      </div>
    </div>
  )
}
